var scrollM = {

    init: function() {

        if($('.c-section-cv').length > 0) {

            $('.c-section-cv-item').each(function(index, el) {

                var watcher = scrollMonitor.create(el);
                watcher.enterViewport(function(event, watcher) {

                    watcher.destroy();

                    var $item = $(this.watchItem);

                    setTimeout(function() {
                        $item.addClass('animate-in');
                    }, 200);
                });
            });
        }
    }
};