var animateBlocks = {

    init: function() {

        showDiv($('.c-section-cv-item:first'));

        function showDiv(div) {

            div.animate({
                opacity: 1
            }, 750, function () {
                showDiv(div.next("div"));
            });
        }

        showP($('.c-section-treatments-intro p:first'));

        function showP(p) {

            p.animate({
                opacity: 1
            }, 750, function () {
                showP(p.next('p'));
            });
        }
    }
};