var royalslider = {

    init: function() {

        $('.c-header-slider').royalSlider({
            slidesSpacing: 0,
            arrowsNav: false,
            loop: false,
            keyboardNavEnabled: true,
            controlsInside: false,
            imageScaleMode: 'fit',
            imageScalePadding: 0,
            imageAlignCenter: 'true',
            arrowsNavAutoHide: false,
            autoScaleSlider: true,
            controlNavigation: 'bullets',
            autoScaleSliderHeight: 250,
            navigateByClick: true,
            startSlideId: 0,
            transitionType:'move',
            globalCaption: false,
            numImagesToPreload: 2,
            //loopRewind: false,
            deeplinking: {
                enabled: true,
                change: false
            },
            autoPlay: {
                enabled: true,
                pauseOnHover: true,
                delay: 4000
            }
        });
    }
};