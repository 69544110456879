var semanticSidebar = {

    init: function() {

        $('.ui.sidebar').sidebar({
            scrollLock: true
        });

        $('#menuBtn').on('click', function(e) {

            e.preventDefault();
            $('.ui.sidebar').sidebar('toggle');
        });
    }
};