var maps = {

    defauls: {mapOptions: {} },

    init: function(options, parking) {

        var self = this;

        self.options = $.extend( {}, self.defaults, options);

        if (typeof google === 'object' && typeof google.maps === 'object') {
            google.maps.event.addDomListener(window, 'load',  $.proxy( this.googleMapIsReady(parking), this ));
        }else{
            console.log("Google maps script not loaded");
        }
    },

    googleMapIsReady: function(parking) {

        var self = this;

        var myLatLng = {lat: self.options.lat, lng: self.options.lng};

        var map = new google.maps.Map(document.getElementById('map'), {
            center: myLatLng,
            zoom: 16,
            gestureHandling: "cooperative",
            styles: [
                {
                    "featureType": "administrative",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#444444"
                        }
                    ]
                },
                {
                    "featureType": "administrative.country",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "visibility": "off"
                        },
                        {
                            "hue": "#ff0000"
                        },
                        {
                            "saturation": "94"
                        },
                        {
                            "lightness": "88"
                        },
                        {
                            "weight": "3.01"
                        },
                        {
                            "invert_lightness": true
                        }
                    ]
                },
                {
                    "featureType": "landscape",
                    "elementType": "all",
                    "stylers": [
                        {
                            "color": "#f2f2f2"
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "all",
                    "stylers": [
                        {
                            "saturation": -100
                        },
                        {
                            "lightness": 45
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "simplified"
                        }
                    ]
                },
                {
                    "featureType": "road.arterial",
                    "elementType": "labels.icon",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "transit",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "all",
                    "stylers": [
                        {
                            "color": "#ffffff"
                        },
                        {
                            "visibility": "on"
                        }
                    ]
                }
            ]
        });

        var marker = new google.maps.Marker({
            position: myLatLng,
            map: map,
            icon: {
                //anchor: new google.maps.Point(30, 30.26),
                scaledSize: new google.maps.Size(45, 45),
                fillColor: '#ac844c',
                url: '/_prototype/dist/images/icons/location.svg'
            }
        });

        var i;
        var markers = [];

        for (i = 0; i < parking.length; i++) {
            marker = new google.maps.Marker({
                position: new google.maps.LatLng(parking[i][0], parking[i][1]),
                map: map,
                icon: {
                    //anchor: new google.maps.Point(30, 30.26),
                    scaledSize: new google.maps.Size(35, 35),
                    fillColor: '#ac844c',
                    url: '/_prototype/dist/images/icons/parking.svg'
                }
            });

            markers.push(marker);
        }
    },
};